import React, { useState } from "react";

function ShowDeleteConfirmation({
  moduleGuid,
  closeModal,
  deleteModuleHandler,
}: {
  moduleGuid: string;
  closeModal: () => void;
  deleteModuleHandler: () => Promise<void>;
}) {
  const [confirmText, setConfirmText] = useState(""); // State to hold the input text

  const deleteModuleCaller = async () => {
    try {
      deleteModuleHandler();
      closeModal();
    } catch (error) {
      console.error("Error deleting module:", error);
    }
  };

  // Function to handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.target.value);
  };

  return (
    <div className="">
      <h6>
        Your Module GUID is <b>{moduleGuid}</b>
      </h6>
      <input
        type="text"
        className="form-control"
        value={confirmText}
        onChange={handleInputChange}
        placeholder="Type 'Delete' to confirm deletion"
      />

      <button
        className="btn btn-danger my-2 mx-auto"
        onClick={deleteModuleCaller}
        disabled={confirmText !== "Delete"}
      >
        Delete Module
      </button>
    </div>
  );
}

export default ShowDeleteConfirmation;
