import moment from "moment";
import { InfoModuleData, UpdateApplianceData } from "../helper/Model";
import apiInterceptor from "./apiInterceptor";

export function getAddress() {
  return apiInterceptor.get<any>("/usermanagement/v1/superAdmin/address");
}

export function getUserAddress(searchValue?: string, pageNumber?: number) {
  return apiInterceptor.get<any>(
    `/usermanagement/v1/superAdmin/userAddress/search?value=${searchValue}&pageNumber=${pageNumber}&pageSize=50&orderBy=ASC`
  );
}

export function getModule(guid: string | undefined) {
  return apiInterceptor.get<any>(
    `/homifi/v1/superAdmin/module?userAddressGuid=${guid}`
  );
}
// http://localhost:8080

export function pingRequest(guid: string | undefined) {
  return apiInterceptor.post(
    `/homifi/v1/superAdmin/module/ping?moduleGuid=${guid}`
  );
}

export function moduleHealthLogRequest(
  guid: string | undefined,
  pageNumber: number,
  pageSize: number,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null
) {
  if (startDate !== null && endDate !== null) {
    return apiInterceptor.get(
      `/homifi/v1/superAdmin/module/${guid}/moduleHealthLog?pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate.format(
        "YYYY-MM-DDTHH:MM:SS"
      )}&endDate=${endDate.format("YYYY-MM-DDTHH:MM:SS")}`
    );
  } else {
    return apiInterceptor.get(
      `/homifi/v1/superAdmin/module/${guid}/moduleHealthLog?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }
}

export function getAllModule(
  pageNumber: number,
  itemPerPage: number,
  sortBy: string,
  orderBy: string,
  searchValue: string
) {
  if (searchValue.length > 0) {
    return apiInterceptor.get(
      `/homifi/v1/superAdmin/module/search?value=${searchValue}&pageNumber=${pageNumber}&pageSize=${itemPerPage}&orderBy=${orderBy}&sortBy=${sortBy}`
    );
  } else {
    return apiInterceptor.get(
      `/homifi/v1/superAdmin/module?pageNumber=${pageNumber}&pageSize=${itemPerPage}&sortBy=${sortBy}&orderBy=${orderBy}`
    );
  }
}
//  superAdmin/module/search?value=f7cd37bb4a554447959b424aa618ac71&pageNumber=0&pageSize=2&orderBy=ASC&sortBy=networkStrength'
// homifi/v1/superAdmin/module/c9f081a2d2fd4545b3a8cb2c36341ed9

export function updateModuleData(
  json: InfoModuleData,
  guid: string | undefined
) {
  return apiInterceptor.put(`/homifi/v1/superAdmin/module/${guid}`, json);
}

export function deleteModuleData(guid: string) {
  return apiInterceptor.delete(`/homifi/v1/superAdmin/module/${guid}`);
}

export function updateAppliance(data: UpdateApplianceData) {
  return apiInterceptor.put(
    `/homifi/v1/superAdmin/appliance/${data.data.guid}`,
    data.data
  );
}
export function deleteApplianceRemote(guid: string, isForceDelete: boolean) {
  return apiInterceptor.delete(
    `/homifi/v1/superAdmin/applianceRemote/${guid}?isForceDelete=${isForceDelete}`
  );
}
