import React, { useEffect, useState } from "react";
import "../../assets/strength.css";

const Strength = ({ networkStrength }: { networkStrength: number }) => {
  const [color, setColor] = useState<string>("green");

  useEffect(() => {
    if (networkStrength >= -90 && networkStrength < -75) {
      setColor("red");
    } else if (networkStrength >= -75 && networkStrength < -60) {
      setColor("yellow");
    } else if (networkStrength >= -60 && networkStrength < -45) {
      setColor("blue"); /* Initially color was orange instead of blue */
    } else if (networkStrength >= -45 && networkStrength < -30) {
      setColor("darkgreen");
    } else {
      setColor("green");
    }
  }, [networkStrength]);
  return (
    <>
      {color === "red" && (
        <div className="net">
          <div className="bars" id="bars" data-bars="1"><span></span><span></span><span></span><span></span></div>
        </div>
      )}
      {color === "yellow" && (
        <div className="net">
          <div className="bars" id="bars" data-bars="2"><span></span><span></span><span></span><span></span></div>
        </div>
      )}
      {/* Initially color was orange instead of blue */}
      {color === "blue" && (
        <div className="net">
          <div className="bars" id="bars" data-bars="3"><span></span><span></span><span></span><span></span></div>
        </div>
      )}
      {color === "darkgreen" && (
        <div className="net">
          <div className="bars" id="bars" data-bars="4"><span></span><span></span><span></span><span></span></div>
        </div>
      )}
    </>
  );
};

export default Strength;
