import React, { useEffect, useState } from "react";

import { Outlet, useLocation } from "react-router-dom";
const MasterLayout = () => {
  const currentRout = useLocation();
  // eslint-disable-next-line
  const [sectionType, setSectionType] = useState("address");

  useEffect(() => {
    if (currentRout.pathname === "/all-module") {
      setSectionType("module");
    } else {
      setSectionType("address");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div
        className="m-auto border-black border-2"
        style={{ borderRadius: "50px", width: "90%" }}
      >
        {/* <div className="row overflow-hidden ">
          <div
            className={`col-6 py-2 text-center pointer ${
              sectionType === "address"
                ? "text-white  bg-primary"
                : "border border-primary text-primary"
            } `}
            style={{
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              fontSize: "16px",
            }}
            onClick={() => {
              setSectionType("address");
              navigate("/all-address");
            }}
          >
            All Address
          </div>
          <div
            className={`col-6 py-2 text-center pointer ${
              sectionType === "module"
                ? "text-white  bg-primary"
                : "border border-primary text-primary"
            } `}
            style={{
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              fontSize: "16px",
            }}
            onClick={() => {
              setSectionType("module");
              navigate("/all-module");
            }}
          >
            All Modules
          </div>
        </div> */}
      </div>
      <br></br>
      <Outlet />
    </div>
  );
};

export default MasterLayout;
