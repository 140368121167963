import React from "react";
import "./assets/bootstrap/css/bootstrap.min.css";
// import "./assets/bootstrap/js/bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./hooks/Auth";
import AppRoutes from "./AppRoutes";
import "./assets/global.css";
import { BrowserRouter } from "react-router-dom";
const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <AuthProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  );
}
