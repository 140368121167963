import React, { useEffect } from "react";
import "../../assets/style.css";
interface ModalProps {
  children: React.ReactNode;
  closeModal: () => void;
}
const Modal = ({ closeModal, children }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);
  return (
    <div>
      <div className="form-modal-wrapper" onClick={closeModal}></div>
      <div className="tran-form-modal-container">
        {" "}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
