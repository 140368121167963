// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAddress, getUserAddress } from "../services/Api";
import { Address } from "../helper/Model";
import Pagination from "../components/base/Pagination";
import { StyledTableContainer, StyledTableHead, StyledTableBody } from '../components/base/CustomTable';
import { TableCell,Table,TableRow} from "@mui/material";

const AllAddress = () => {
  const [address, setAddress] = useState<Address[]>([]);

  const [pageNumber, setPageNumber] = useState(0);

  const [numberOfPages, setNumberOfPages] = useState(1);
  // const [searchAddress, setSearchAddress] = useState<Address[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    searchAddressHandler();
    // eslint-disable-next-line
  }, [pageNumber]);

  const searchAddressHandler = async () => {
    try {
      // pageNumber, itemPerPage, sortBy, orderBy, searchValue)
      if (searchValue.length > 0) {
        getUserAddress(searchValue, pageNumber)
          .then((response) => {
            setAddress(response.data.data);
            setNumberOfPages(response.data.dataSize);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getAddress()
          .then((response) => {
            setAddress(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };
  return (
    <div
      className=" col-11 col-md-6 col-lg-6 m-auto mt-5"
      style={{ overflowX: "auto" }}
    >
      <div className="filter-box py-2">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon2"
            value={searchValue}
            onChange={handleSearchInputChange}
            autoFocus={true}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => {
                searchAddressHandler();
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <h4>All Address</h4>
      <div style={{  margin: "auto", overflowX: "auto" , borderRadius:"15px"}}>
      <div className="m-auto mt-5">
      <StyledTableContainer className="mt-2">
        <Table className="table table-sm">
        <StyledTableHead>
          <TableRow className="">
            <TableCell scope="col">Sr. No</TableCell>
            <TableCell scope="col">Name</TableCell>
            <TableCell scope="col">City</TableCell>
            <TableCell scope="col">Address</TableCell>
            <TableCell scope="col">Contact Number</TableCell>
            <TableCell scope="col">Action</TableCell>
          </TableRow>
        </StyledTableHead>
        <StyledTableBody>
          {address.map((item, index) => (
            <TableRow key={item.guid} className="bg-white">
              <TableCell scope="row">{index + 1}</TableCell>
              <TableCell>{item.displayName}</TableCell>
              <TableCell>{item.cityName}</TableCell>
              <TableCell>{item.streetAddress2}</TableCell>
              <TableCell>{item.contactNumber}</TableCell>
              <TableCell>
                <Link to={`/details/${item.guid}`}>View</Link>
              </TableCell>
            </TableRow>
          ))}
        </StyledTableBody>
        </Table>
      </StyledTableContainer>
      </div>
      </div>
      <Pagination
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        numberOfPages={numberOfPages}
      />
    </div>
  );
};

export default AllAddress;
