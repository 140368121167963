import {
  FC,
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";
type WithChildren = {
  children?: ReactNode;
};
type AuthContextProps = {
  isLoggedIn: boolean | undefined;
  setIsLoggedIn: Dispatch<SetStateAction<boolean | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  saveAuth: () => {},
  isLoggedIn: undefined,
  setIsLoggedIn: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(false);

  const logout = () => {
    setIsLoggedIn(undefined);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
