import React, { useState } from "react";
import { ApplianceDto } from "../../helper/Model";
import { IoMdClose } from "react-icons/io";
import { useFormik } from "formik";
import { updateAppliance } from "../../services/Api";
import { useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { StyledTableContainer, StyledTableHead, StyledTableBody } from '../base/CustomTable';
import { TableCell,Table,TableRow} from "@mui/material";

const ShowAppliance = ({
  appliances,
  closeModal,
  fetchModules,
  isOpen=false,
}: {
  appliances: ApplianceDto[];
  closeModal: () => void;
  fetchModules: () => Promise<void>;
  isOpen: boolean | undefined;
}) => {
  const [isEditable, setIsEditable] = useState(false);
  // const [editData, setEditData] = useState<ApplianceDto>();
  const { guid } = useParams();
  const initialValues: ApplianceDto = {
    guid: "",
    moduleIdGuid: "",
    applianceTypeIdName: "",
    name: "",
    displayName: "",
    appliancePort: "",
    applianceIntensity: 0,
    applianceStatus: "",
    parentApplianceIdGuid: "",
    isRememberState: "",
    status: "",
    lastUpdatedByFullName: "",
    lastUpdatedByGuid: "",
    lastUpdatedTimestamp: "",
    useCount: 0,
    isHidden: "",
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await updateAppliance({ guid: guid, data: values }).then(
          (response: any) => {
            setIsEditable(false);
            fetchModules();
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      maxWidth="xl" // maxWidth is 'xl' on all screens
      fullWidth
      scroll="body"
    >
      <div>
        <DialogTitle>
      <div className="d-flex justify-content-between">
        <h4>Appliance</h4>
        <div className="pointer" onClick={() => closeModal()}>
          <IoMdClose style={{ fontSize: "22px", padding: "0" }} />
        </div>
      </div>
      </DialogTitle>

      <DialogContent>
      {isEditable ? (
        <div>
          <form
            className="form w-100 "
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="row">
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="guid">
                  GUID
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="guid"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.guid}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="status">
                  Status
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="name">
                  Name
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="displayName">
                  Display Name
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="displayName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.displayName}
                />
              </div>

              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="applianceTypeIdName">
                  Appliance TypeIdName
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="applianceTypeIdName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.applianceTypeIdName}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="applianceIntensity">
                  Appliance Intensity
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="applianceIntensity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.applianceIntensity}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="isHidden">
                  isHidden
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="isHidden"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.isHidden}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="applianceStatus">
                  Appliance Status
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="applianceStatus"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.applianceStatus}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <button className="btn btn-primary w-100" type="submit">
                  Update
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => setIsEditable(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div style={{ maxHeight: "500px" , borderRadius:"15px"  }}>
          <StyledTableContainer className="" style={{overflowX:"auto"}}>
            <Table>
            <StyledTableHead>
              <TableRow className="">
                <TableCell scope="col">Sr.No</TableCell>
                <TableCell scope="col">Name</TableCell>
                <TableCell scope="col">Display Name</TableCell>
                <TableCell scope="col">Appliance Type</TableCell>
                <TableCell scope="col">isHidden</TableCell>
                <TableCell scope="col">Status</TableCell>
                <TableCell scope="col">Intensity</TableCell>
                <TableCell scope="col">Port</TableCell>
                <TableCell scope="col">GUID</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {appliances.map((item, index) => (
                <TableRow key={item.guid}>
                  <TableCell scope="row">{index + 1}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.displayName}</TableCell>
                  <TableCell>{item.applianceTypeIdName}</TableCell>
                  <TableCell>{item.isHidden}</TableCell>
                  <TableCell>{item.applianceStatus}</TableCell>
                  <TableCell>{item.applianceIntensity}</TableCell>
                  <TableCell>{item.appliancePort}</TableCell>
                  <TableCell className="text-center">{item.guid}</TableCell>
                  <TableCell
                    className="text-center pointer"
                    onClick={() => {
                      // setEditData(item);
                      formik.setValues(item);
                      setIsEditable(true);
                    }}
                  >
                    <FaEdit style={{ fontSize: "18px" }} />
                  </TableCell>
                </TableRow>
              ))}
            </StyledTableBody>
            </Table>
          </StyledTableContainer>
        </div>  
      )}
      </DialogContent>
    </div>
    </Dialog>
  );
};

export default ShowAppliance;
