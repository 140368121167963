import React, { FC } from "react";

import { GrChapterPrevious, GrChapterNext } from "react-icons/gr";
interface Props {
  pageNumber: number;
  numberOfPages: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}
const Pagination: FC<Props> = ({
  pageNumber,
  numberOfPages,
  setPageNumber,
}) => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-end m-0">
        <li className="page-item" onClick={() => setPageNumber(0)}>
          <div className="page-link">First</div>
        </li>
        <li
          className="page-item"
          onClick={() => {
            if (pageNumber > 0) {
              setPageNumber(pageNumber - 1);
            }
          }}
        >
          <div className="page-link">
            <GrChapterPrevious />
          </div>
        </li>
        <li className="">
          <div
            className=" bg-primary text-white d-flex align-items-center justify-content-center"
            style={{
              height: "100%",
              width: "50px",
            }}
          >
            {" "}
            <span style={{ fontWeight: "bold" }}>{pageNumber + 1}</span>
          </div>
        </li>
        <li className="page-item">
          <div
            className="page-link"
            onClick={() => {
              if (pageNumber < numberOfPages - 1) {
                setPageNumber(pageNumber + 1);
              }
            }}
          >
            <GrChapterNext />
          </div>
        </li>
        <li
          className="page-item"
          onClick={() => setPageNumber(numberOfPages - 1)}
        >
          <div className="page-link">Last</div>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
