import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CgMore } from "react-icons/cg";

import { IoMdClose } from "react-icons/io";
import {
  getAddress,
  getModule,
  moduleHealthLogRequest,
  pingRequest,
} from "../services/Api";
import {
  ApplianceDto,
  ApplianceRemoteDto,
  InfoModuleData,
  ModuleData,
  ModuleHealthLog,
} from "../helper/Model";
import DisplayAddressElement from "../components/base/DisplayAddressElement";
import ShowAppliance from "../components/modal/ShowAppliance";
import ShowApplianceRemote from "../components/modal/ShowApplianceRemote";
import ShowInfoModule from "../components/modal/ShowInfoModule";
import moment from "moment-timezone";
import Strength from "../components/base/Strength";
import Pagination from "../components/base/Pagination";
import {
  StyledTableContainer,
  StyledTableHead,
  StyledTableBody,
} from "../components/base/CustomTable";
import { TableCell, Table, TableRow } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface Address {
  addressRegistrationNumber: string;
  cityName: string;
  contactNumber: string;
  displayName: string;
  guid: string;
  lastUpdatedByFullName: string;
  lastUpdatedByGuid: string;
  lastUpdatedTimestamp: string;
  ownerFullName: string;
  ownerGuid: string;
  pinCode: string;
  premiseTypeName: string;
  status: string;
  streetAddress1: string;
  streetAddress2: string;
  userAddressStatus: string;
}

const DetailsPage = () => {
  const [address, setAddress] = useState<Address>();
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [showApplianceDtoList, setShowApplianceDtoList] = useState(false);
  const [showInfo, setShowInfo] = useState<boolean | undefined>(false);
  const [showRemoteDtoList, setShowRemoteDtoList] = useState(false);
  const [showModuleHealthLog, setShowModuleHealthLog] = useState(false);
  const [numOfModules, setNumOfModules] = useState("0");
  const [module, setModule] = useState<ModuleData[]>();
  const [infoModuleData, setInfoModuleData] = useState<InfoModuleData>();
  const [moduleLog, setModuleLog] = useState<ModuleHealthLog[]>();
  const [filteredModules, setFilteredModules] = useState<ModuleData[]>();
  const [applianceRemoteDtoList, setApplianceRemoteDtoList] =
    useState<ApplianceDto[]>();
  const [remoteDtoList, setRemoteDtoList] = useState<ApplianceRemoteDto[]>();
  const [connectivityFilter, setConnectivityFilter] = useState<string>("ALL");
  const [currentLogGuid, setCurrentLogGuid] = useState("");

  const [pageSize] = useState(25);
  const { guid } = useParams();
  const [localStartDate, setLocalStartDate] = useState<string>("");
  const [localEndDate, setLocalEndDate] = useState<string>("");
  const [utcStartDate, setUTCStartDate] = useState<moment.Moment | null>(null);
  const [utcEndDate, setUTCEndDate] = useState<moment.Moment | null>(null);

  const handleLocalStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalStartDate(event.target.value);
    convertToUTC(event.target.value, localEndDate);
  };

  const handleLocalEndDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalEndDate(event.target.value);
    convertToUTC(localStartDate, event.target.value);
  };

  const convertToUTC = (start: string, end: string) => {
    if (start && end) {
      const utcStart = moment.tz(start, moment.tz.guess()).utc();
      const utcEnd = moment.tz(end, moment.tz.guess()).utc();
      setUTCStartDate(utcStart);
      setUTCEndDate(utcEnd);
    }
  };

  const [menuStates, setMenuStates] = useState<{
    [key: string]: null | HTMLElement;
  }>({});

  const handleClick = (event: React.MouseEvent<HTMLElement>, guid: string) => {
    setMenuStates({ ...menuStates, [guid]: event.currentTarget });
  };

  const handleClose = (guid: string) => {
    setMenuStates({ ...menuStates, [guid]: null });
  };

  const fetchModules = async () => {
    try {
      await getModule(guid)
        .then((response) => {
          setModule(response.data.data);
          setFilteredModules(response.data.data);
          setNumOfModules(response.data.data.length);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  const pingHandler = async (guid: string | undefined) => {
    try {
      await pingRequest(guid)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  const handleModuleLog = async () => {
    if (currentLogGuid.length > 0) {
      try {
        await moduleHealthLogRequest(
          currentLogGuid,
          pageNumber,
          pageSize,
          utcStartDate,
          utcEndDate
        )
          .then((response) => {
            setModuleLog(response.data.data);

            setNumberOfPages(response.data.dataSize);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error fetching :", error);
      }
    }
  };

  useEffect(() => {
    handleModuleLog();
    // eslint-disable-next-line
  }, [pageNumber, currentLogGuid, utcStartDate, utcEndDate]);

  const fetchAddress = async () => {
    try {
      getAddress()
        .then((response) => {
          const filteredData = response.data.data.filter(
            (item: any) => item.guid === guid
          );
          setAddress(filteredData[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  useEffect(() => {
    fetchAddress();
    fetchModules();
    // eslint-disable-next-line
  }, [guid]);

  const filterHandler = (connectivityFilter: string) => {
    const filteredModules = module?.filter((item) => {
      if (connectivityFilter === "ALL") {
        return true;
      } else {
        return item.connectivityStatus === connectivityFilter;
      }
    });
    setFilteredModules(filteredModules);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setConnectivityFilter(event.target.value);
    filterHandler(event.target.value);
  };

  return (
    <>
      <div
        className="card p-4 m-auto"
        style={{ width: "90%", borderRadius: "10px" }}
      >
        <h2 className="fs-1 mb-3">{address?.displayName}</h2>
        <div className="row justify-content-between fs-6">
          <div className="col-12 col-md-6 col-xxl-5">
            <DisplayAddressElement name="GUID" value={address?.guid} />
            <DisplayAddressElement
              name="Address"
              value={address?.streetAddress2}
            />
            <DisplayAddressElement name="City" value={address?.cityName} />
            <DisplayAddressElement name="Pin Code" value={address?.pinCode} />
            <DisplayAddressElement
              name="Number Of Modules"
              value={numOfModules}
            />
          </div>
          <div className="col-12 col-md-6 col-xxl-5">
            <DisplayAddressElement
              name="Contact"
              value={address?.contactNumber}
            />
            <DisplayAddressElement
              name="Owner"
              value={address?.ownerFullName}
            />
            <DisplayAddressElement
              name="Last Updated"
              value={address?.lastUpdatedByFullName}
            />
            <DisplayAddressElement name="Status" value={address?.status} />
          </div>
        </div>
      </div>

      <div style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex justify-content-start align-items-center my-2">
          <label className="m-0 pr-2 fs-1"> Status Wise:</label>
          <div style={{ width: "400px" }}>
            <select
              className="form-select form-select-lg my-2 w-50 p-1"
              value={connectivityFilter}
              onChange={handleFilterChange}
            >
              <option value="ALL">All</option>
              <option value="CONNECTED">Connected</option>
              <option value="DISCONNECTED">Disconnected</option>
            </select>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "auto",
          overflowX: "auto",
          borderRadius: "15px",
        }}
      >
        <div className="m-auto mt-5">
          <StyledTableContainer className="mt-2">
            <Table className="table table-sm">
              <StyledTableHead>
                <TableRow className="">
                  <TableCell scope="col">Sr. No</TableCell>
                  <TableCell scope="col">Name</TableCell>
                  <TableCell scope="col">Display Name</TableCell>
                  <TableCell scope="col">IP Address</TableCell>
                  <TableCell scope="col">Type</TableCell>
                  <TableCell scope="col">Version</TableCell>
                  <TableCell scope="col">Connectivity Status</TableCell>
                  <TableCell scope="col">Connectivity Timestamp</TableCell>
                  <TableCell scope="col">Network Strength</TableCell>
                  <TableCell scope="col">Action</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {filteredModules?.map((item, index) => (
                  <TableRow key={item.guid}>
                    <TableCell scope="row">{index + 1}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.displayName}</TableCell>
                    <TableCell>{item.ipAddress}</TableCell>
                    <TableCell>{item.moduleTypeDisplayName}</TableCell>
                    <TableCell>{item.firmwareVersion}</TableCell>
                    <TableCell>
                      <label
                        className={`badge badge-${
                          item.connectivityStatus === "CONNECTED"
                            ? "success"
                            : "danger"
                        }`}
                      >
                        {item.connectivityStatus}
                      </label>
                    </TableCell>
                    <TableCell>
                      {" "}
                      {moment
                        .utc(item.connectivityStatusTimestamp)
                        .local()
                        .format("DD MMM YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <Strength networkStrength={item.networkStrength} />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                      className="text-center link-click"
                    >
                      <div>
                        <Button
                          id={`basic-button-${item.guid}`}
                          aria-controls={`basic-menu-${item.guid}`}
                          aria-haspopup="true"
                          aria-expanded={
                            Boolean(menuStates[item.guid]) ? "true" : undefined
                          }
                          onClick={(event) => handleClick(event, item.guid)}
                        >
                          <CgMore />
                        </Button>
                        <Menu
                          id={`basic-menu-${item.guid}`}
                          anchorEl={menuStates[item.guid]}
                          open={Boolean(menuStates[item.guid])}
                          onClose={() => handleClose(item.guid)}
                          MenuListProps={{
                            "aria-labelledby": `basic-button-${item.guid}`,
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              pingHandler(item.guid);
                              handleClose(item.guid);
                            }}
                          >
                            Ping
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCurrentLogGuid(item.guid);
                              setShowModuleHealthLog(!showModuleHealthLog);
                              handleClose(item.guid);
                            }}
                          >
                            Log
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setShowApplianceDtoList(!showApplianceDtoList);
                              setApplianceRemoteDtoList(item.applianceDtoList);
                              handleClose(item.guid);
                            }}
                          >
                            Appliance
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setRemoteDtoList(item.applianceRemoteDtoList);
                              setShowRemoteDtoList(!showRemoteDtoList);
                              handleClose(item.guid);
                            }}
                          >
                            Remotes
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setInfoModuleData({
                                guid: item.guid,
                                status: item.status,
                                userAddressIdGuid: item.userAddressIdGuid,
                                name: item.name,
                                displayName: item.displayName,
                                internalSerialNumber: item.internalSerialNumber,
                                ipAddress: item.ipAddress,
                                moduleTypeDisplayName:
                                  item.moduleTypeDisplayName,
                                moduleModelDisplayName:
                                  item.moduleModelDisplayName,
                                firmwareVersion: item.firmwareVersion,
                                isMapped: item.isMapped,
                                connectivityStatus: item.connectivityStatus,
                                lastUpdatedByFullName:
                                  item.lastUpdatedByFullName,
                                networkStrength: item.networkStrength,
                                connectivityStatusTimestamp:
                                  item.connectivityStatusTimestamp,
                              });

                              setShowInfo(!showInfo);
                              handleClose(item.guid);
                            }}
                          >
                            Info
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </StyledTableContainer>
        </div>
      </div>

      <br />
      {/*==============Module=========== */}
      {showApplianceDtoList && applianceRemoteDtoList && (
        <ShowAppliance
          closeModal={() => {
            setShowApplianceDtoList(!showApplianceDtoList);
          }}
          appliances={applianceRemoteDtoList}
          fetchModules={fetchModules}
          isOpen={showApplianceDtoList}
        />
      )}
      {showInfo && (
        <>
          {infoModuleData && (
            <ShowInfoModule
              guid={guid}
              infoModuleData={infoModuleData}
              closeModal={() => setShowInfo(!showInfo)}
              isOpen={showInfo}
            />
          )}
        </>
      )}
      {showRemoteDtoList && remoteDtoList && (
        <ShowApplianceRemote
          closeModal={() => setShowRemoteDtoList(!showRemoteDtoList)}
          appliancesRemote={remoteDtoList}
          fetchModules={fetchModules}
          isOpen={showRemoteDtoList}
        />
      )}

      {showModuleHealthLog && (
        <Dialog
          open={showModuleHealthLog}
          maxWidth="lg"
          fullWidth
          scroll="body"
        >
          {moduleLog && (
            <div className="p-2">
              <DialogTitle>
                <div className="d-flex justify-content-between">
                  <h4>Log Health</h4>
                  <div
                    className="pointer"
                    onClick={() => {
                      setShowModuleHealthLog(false);
                    }}
                  >
                    <IoMdClose style={{ fontSize: "22px", padding: "0" }} />
                  </div>
                </div>
              </DialogTitle>

              <DialogContent>
                <div className="row">
                  <div className="col-6 d-flex flex-column">
                    <div style={{ width: "100px" }}>Start Date:</div>

                    <input
                      className="form-control input-lg"
                      type="datetime-local"
                      value={localStartDate}
                      onChange={handleLocalStartDateChange}
                    />
                  </div>
                  <div className="col-6 d-flex flex-column">
                    <div style={{ width: "100px" }}>End Date:</div>
                    <input
                      className="form-control  input-sm"
                      type="datetime-local"
                      value={localEndDate}
                      onChange={handleLocalEndDateChange}
                    />
                  </div>
                </div>

                <div
                  className="shadow-sm my-2"
                  style={{
                    maxHeight: "60vh",
                    overflow: "auto",
                    borderRadius: "15px",
                  }}
                >
                  <StyledTableContainer className="">
                    <Table>
                      <StyledTableHead>
                        <TableRow className="">
                          <TableCell scope="col">Sr. No</TableCell>
                          <TableCell scope="col">Display Name</TableCell>
                          <TableCell scope="col">IP Address</TableCell>
                          <TableCell scope="col">Appliance Status</TableCell>
                          <TableCell scope="col">Time </TableCell>
                          <TableCell scope="col">Network </TableCell>
                          <TableCell scope="col">Firmware </TableCell>
                        </TableRow>
                      </StyledTableHead>
                      <StyledTableBody>
                        {moduleLog.map((item, index) => (
                          <TableRow key={item.guid}>
                            <TableCell scope="row">{index + 1}</TableCell>
                            <TableCell>{item.moduleDisplayName}</TableCell>
                            <TableCell>{item.ipAddress}</TableCell>
                            <TableCell>
                              <label
                                className={` badge badge-${
                                  item.moduleStatus === "CONNECTED"
                                    ? "success"
                                    : item.moduleStatus === "DISCONNECTED"
                                    ? "danger"
                                    : "warning"
                                }`}
                              >
                                {item.moduleStatus}
                              </label>
                            </TableCell>
                            <TableCell>
                              {" "}
                              {moment
                                .utc(item.lastUpdatedTimestamp)
                                .local()
                                .format("DD MMM YYYY HH:mm:ss")}
                            </TableCell>
                            <TableCell>{item.networkStrength}</TableCell>
                            <TableCell>{item.moduleFirmwareVersion}</TableCell>
                          </TableRow>
                        ))}
                      </StyledTableBody>
                    </Table>
                  </StyledTableContainer>
                </div>
                <Pagination
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  numberOfPages={numberOfPages}
                />
              </DialogContent>
            </div>
          )}
        </Dialog>
      )}
      <br />
    </>
  );
};

export default DetailsPage;
