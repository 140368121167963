import React, { useState } from "react";
import { useAuth } from "../hooks/Auth";
import { setAuth } from "../helper/AuthHelpers";
import homifi from "../assets/homifi.png";
import apiInterceptor from "../services/apiInterceptor";
const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setIsLoggedIn } = useAuth();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await apiInterceptor
        .post("/auth", {
          value: username,
          password,
        })
        .then((response: any) => {
          setAuth({ authorization: response.headers.authorization });
          if (!response.headers.authorization) {
            setError("Invalid username or password");
          } else {
            setIsLoggedIn(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setError("Invalid username or password");
        });
    } catch (err) {
      setError("Invalid username or password");
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-5  bg-white col-11 m-5  p-5">
          <div className="border-bottom  d-flex justify-content-center flex-column">
            <div className="d-flex justify-content-center ">
              <img
                src={homifi}
                className="mb-4"
                style={{ height: "65px" }}
                alt="homifi-logo"
              />
            </div>
            <h2 className="bold text-center p-0 m-0">Admin Login</h2>
            <label className=" text-muted text-center  " htmlFor="">
              Please enter your details to login
            </label>
          </div>
          <form onSubmit={handleSubmit}>
            {error ? (
              <div className=" text-center alert alert-danger p-2 my-2">
                {error}
              </div>
            ) : (
              <br />
            )}

            <div className="form-group">
              <label className="m-0 ">Username:</label>
              <input
                type="text"
                className="form-control"
                value={username}
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="m-0">Password:</label>
              <input
                type="password"
                className="form-control"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 rounded-pill"
            >
              Login
            </button>
            <div className="text-center mt-3 px-2">
              Contact admin if you do not have a{" "}
              <span className="text-primary">username and password</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
