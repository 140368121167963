import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

export const StyledTableContainer = styled(TableContainer)(() => ({
  borderCollapse: "collapse",
  margin: "25px 0",
  fontSize: "0.8rem",
  fontFamily: "sans-serif",
  minWidth: "400px",
  "@media (min-width: 1326px)": {
    overflowX: "hidden",
  },
}));

export const StyledTableHead = styled(TableHead)(() => ({
  backgroundColor: "#a52a2a",
  "& th": {
    color: "#ffffff",
    textAlign: "left",
    padding: "12px 15px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
}));

export const StyledTableBody = styled(TableBody)(() => ({
  "& tr": {
    borderBottom: "1px solid #dddddd",
  },
  "& tr:nth-of-type(even)": {
    backgroundColor: "#f3f3f3",
  },
  "& tr:last-of-type": {
    borderBottom: "2px solid #a52a2a",
  },
  "& td": {
    padding: "12px 15px",
    "&:first-of-type": {
      fontWeight: "bold",
    },
  },
}));
