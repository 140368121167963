import axios from "axios";
import { getAuth } from "../helper/AuthHelpers";

const { REACT_APP_URL } = process.env;
const apiInterceptor = axios.create({
  baseURL: REACT_APP_URL || "",
});

apiInterceptor.interceptors.request.use(
  (config: any) => {
    config.headers.Authorization = "" + getAuth()?.authorization;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiInterceptor;
