import React, { useEffect } from "react";
import { useAuth } from "./hooks/Auth";
import MasterLayout from "./pages/MasterLayout";
import AddressInfo from "./pages/AddressInfo";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import { getAuth } from "./helper/AuthHelpers";
import logo from "./assets/niyantraslogo.png";
import { signOut } from "./helper/AuthHelpers";
import AllModules from "./pages/AllModules";
import AllAddress from "./pages/AllAddress";
const AppRoutes = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  useEffect(() => {
    const requestUser = async (apiToken: string | undefined) => {
      if (apiToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };
    requestUser(getAuth()?.authorization);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-light">
      <div className="">
        <div
          className="w-100 bg-white border-bottom d-flex justify-content-between align-items-center mb-4"
          style={{ height: "70px" }}
        >
          <img
            style={{ height: "40px" }}
            className="mx-4"
            src={logo}
            alt="logo"
          />{" "}
          {isLoggedIn && (
            <h3 className="text-center my-4 d-none d-md-block">Homifi Admin Console</h3>
          )}
          <div className="mx-4">
            {isLoggedIn && (
              <button
                className="btn btn-sm btn-danger rounded"
                onClick={signOut}
              >
                Logout
              </button>
            )}
          </div>
        </div>

        {isLoggedIn ? (
          <Routes>
            <Route element={<MasterLayout />}>
              <Route path="/details/:guid" element={<AddressInfo />} />
              <Route path="/all-module" element={<AllModules />} />
              <Route path="/all-address" element={<AllAddress />} />
              <Route index element={<AllAddress />} />
            </Route>
          </Routes>
        ) : (
          <Login />
        )}
      </div>
    </div>
  );
};


export default AppRoutes;
