import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { deleteModuleData, getAllModule, pingRequest } from "../services/Api";
import {
  ApplianceDto,
  ApplianceRemoteDto,
  InfoModuleData,
  ModuleData,
} from "../helper/Model";
import { BsArrowDownUp } from "react-icons/bs";
import moment from "moment";

import { CgMore } from "react-icons/cg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "../components/base/Modal";
import ShowAppliance from "../components/modal/ShowAppliance";
import ShowInfoModule from "../components/modal/ShowInfoModule";
import ShowApplianceRemote from "../components/modal/ShowApplianceRemote";
import Strength from "../components/base/Strength";
import { Link } from "react-router-dom";
import ShowDeleteConfirmation from "../components/modal/ShowDeleteConfirmation";
import Pagination from "../components/base/Pagination";
import { StyledTableContainer, StyledTableHead, StyledTableBody } from '../components/base/CustomTable';
import { TableCell,Table,TableRow} from "@mui/material";

const AllModules = () => {
  //   const [address, setAddress] = useState<Address[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };
  const [showApplianceDtoList, setShowApplianceDtoList] = useState(false);
  const [applianceRemoteDtoList, setApplianceRemoteDtoList] =
    useState<ApplianceDto[]>();
  const [remoteDtoList, setRemoteDtoList] = useState<ApplianceRemoteDto[]>();
  const [showRemoteDtoList, setShowRemoteDtoList] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [infoModuleData, setInfoModuleData] = useState<InfoModuleData>();
  const [showInfo, setShowInfo] = useState(false);
  // const [module, setModule] = useState<ModuleData[]>();
  const [filteredModules, setFilteredModules] = useState<ModuleData[]>();
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemPerPage] = useState(50);
  const [sortBy] = useState("lastUpdatedTimestamp");
  const [orderBy, setOrderBy] = useState("DESC");
  const [deleteModuleGuid, setDeleteModuleGuid] = useState("");
  const fetchModules = async () => {
    // pageNumber, itemPerPage, sortBy, orderBy, searchValue
    try {
      await getAllModule(pageNumber, itemPerPage, sortBy, orderBy, searchValue)
        .then((response) => {
          // setModule(response.data.data);
          setFilteredModules(response.data.data);
          setNumberOfPages(response.data.dataSize);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  useEffect(() => {
    fetchModules();
    // eslint-disable-next-line
  }, [pageNumber, itemPerPage, sortBy, orderBy]);

  const orderByGet = () => {
    if (orderBy === "DESC") {
      setOrderBy("ASC");
    } else {
      setOrderBy("DESC");
    }
  };

  // eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const open = Boolean(anchorEl);
  // eslint-disable-next-line
  const [menuStates, setMenuStates] = useState<{
    [key: string]: null | HTMLElement;
  }>({});

  const handleClick = (event: React.MouseEvent<HTMLElement>, guid: string) => {
    setMenuStates({ ...menuStates, [guid]: event.currentTarget });
  };

  const handleClose = (guid: string) => {
    setMenuStates({ ...menuStates, [guid]: null });
  };

  const pingHandler = async (guid: string | undefined) => {
    try {
      await pingRequest(guid)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  const deleteModuleHandler = async () => {
    try {
      await deleteModuleData(deleteModuleGuid).then(() => {
        fetchModules();
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <div style={{ width: "100%", margin: "auto", overflowX: "auto" }}>
        <div className="filter-box border-bottom py-2">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={searchValue}
              onChange={handleSearchInputChange}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => {
                  fetchModules();
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="m-auto mt-5 overflow-x-auto" style={{borderRadius:"15px"}}>
          <StyledTableContainer className="mt-2">
            <Table className="table table-sm">
            <StyledTableHead>
              <TableRow className="">
                <TableCell scope="col" className="p-2" onClick={() => orderByGet()}>
                  <BsArrowDownUp />
                </TableCell>

                <TableCell scope="col">Name</TableCell>
                <TableCell scope="col">Display Name</TableCell>
                <TableCell scope="col">IP Address</TableCell>
                <TableCell scope="col">Type</TableCell>
                <TableCell scope="col">Version</TableCell>
                <TableCell scope="col">Status</TableCell>
                <TableCell scope="col">Connectivity Timestamp</TableCell>
                <TableCell scope="col">Network</TableCell>
                <TableCell scope="col">Address</TableCell>
                <TableCell scope="col" className="text-center" colSpan={2}>
                  More
                </TableCell>
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {filteredModules?.map((item, index) => (
                <TableRow
                  key={item.guid}
                  style={item.status === "D" ? { background: "#da9090" } : {}}
                >
                  <TableCell scope="row">{index + 1}</TableCell>

                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.displayName}</TableCell>
                  <TableCell>{item.ipAddress}</TableCell>
                  <TableCell>{item.moduleTypeDisplayName}</TableCell>
                  <TableCell>{item.firmwareVersion}</TableCell>
                  <TableCell>
                    <label
                      className={` badge badge-${
                        item.connectivityStatus === "CONNECTED"
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {item.connectivityStatus}
                    </label>
                  </TableCell>
                  <TableCell>
                    {moment
                      .utc(item.connectivityStatusTimestamp)
                      .local()
                      .format("DD MMM YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    <Strength networkStrength={item.networkStrength} />
                  </TableCell>
                  <TableCell>
                    {item.userAddressIdGuid !== "Address not Mapped" ? (
                      <Link to={"/details/" + item.userAddressIdGuid}>
                        {item.userAddressIdGuid}
                      </Link>
                    ) : (
                      item.userAddressIdGuid
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                    className="text-center link-click"
                  >
                    <div>
                      <Button
                        id={`basic-button-${item.guid}`}
                        aria-controls={`basic-menu-${item.guid}`}
                        aria-haspopup="true"
                        aria-expanded={
                          Boolean(menuStates[item.guid]) ? "true" : undefined
                        }
                        onClick={(event) => handleClick(event, item.guid)}
                      >
                        <CgMore />
                      </Button>
                      <Menu
                        id={`basic-menu-${item.guid}`}
                        anchorEl={menuStates[item.guid]}
                        open={Boolean(menuStates[item.guid])}
                        onClose={() => handleClose(item.guid)}
                        MenuListProps={{
                          "aria-labelledby": `basic-button-${item.guid}`,
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            pingHandler(item.guid);
                            handleClose(item.guid);
                          }}
                        >
                          Ping
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            setShowApplianceDtoList(!showApplianceDtoList);
                            setApplianceRemoteDtoList(item.applianceDtoList);
                            handleClose(item.guid);
                          }}
                        >
                          Appliance
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setRemoteDtoList(item.applianceRemoteDtoList);
                            setShowRemoteDtoList(!showRemoteDtoList);
                            handleClose(item.guid);
                          }}
                        >
                          Remotes
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setInfoModuleData({
                              guid: item.guid,
                              status: item.status,
                              userAddressIdGuid: item.userAddressIdGuid,
                              name: item.name,
                              displayName: item.displayName,
                              internalSerialNumber: item.internalSerialNumber,
                              ipAddress: item.ipAddress,
                              moduleTypeDisplayName: item.moduleTypeDisplayName,
                              moduleModelDisplayName:
                                item.moduleModelDisplayName,
                              firmwareVersion: item.firmwareVersion,
                              isMapped: item.isMapped,
                              connectivityStatus: item.connectivityStatus,
                              lastUpdatedByFullName: item.lastUpdatedByFullName,
                              networkStrength: item.networkStrength,
                              connectivityStatusTimestamp:
                                item.connectivityStatusTimestamp,
                            });

                            setShowInfo(!showInfo);
                            handleClose(item.guid);
                          }}
                        >
                          Info
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setDeleteModuleGuid(item.guid);
                            setShowDeleteConfirmation(!showDeleteConfirmation);
                            handleClose(item.guid);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </StyledTableBody>
            </Table>
          </StyledTableContainer>
        </div>
      </div>

      <Pagination
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        numberOfPages={numberOfPages}
      />

      <br />
      {/*==============Module=========== */}
      {showApplianceDtoList && (
          applianceRemoteDtoList && (
            <ShowAppliance
              closeModal={() => setShowApplianceDtoList(!showApplianceDtoList)}
              appliances={applianceRemoteDtoList}
              fetchModules={fetchModules}
              isOpen={showApplianceDtoList}
            />
          )
      )}

      {showInfo && (
        <Modal closeModal={() => setShowInfo(!showInfo)}>
          {infoModuleData && (
            <ShowInfoModule
              isOpen={showInfo}
              guid={infoModuleData.userAddressIdGuid}
              infoModuleData={infoModuleData}
              closeModal={() => setShowInfo(!showInfo)}
            />
          )}
        </Modal>
      )}

      {showRemoteDtoList && (
          remoteDtoList && (
            <ShowApplianceRemote
              closeModal={() => setShowRemoteDtoList(!showRemoteDtoList)}
              appliancesRemote={remoteDtoList}
              fetchModules={fetchModules}
              isOpen={showRemoteDtoList}
            />
          )
      )}

      {showDeleteConfirmation && (
        <Modal
          closeModal={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
        >
          <ShowDeleteConfirmation
            closeModal={() =>
              setShowDeleteConfirmation(!showDeleteConfirmation)
            }
            moduleGuid={deleteModuleGuid}
            deleteModuleHandler={deleteModuleHandler}
          />
        </Modal>
      )}
    </div>
  );
};

export default AllModules;
