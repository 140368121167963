import React, { useState } from "react";
import { ApplianceRemoteDto } from "../../helper/Model";
import { IoMdClose } from "react-icons/io";
import { BsDatabaseFillX } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
// import Modal from "../base/Modal";
import ShowDeleteConfirmationForRemote from "./ShowDeleteConfirmationForRemote";
import { deleteApplianceRemote } from "../../services/Api";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { StyledTableContainer, StyledTableHead, StyledTableBody } from '../base/CustomTable';
import { TableCell, Table, TableRow } from "@mui/material";

const ShowApplianceRemote = ({
  appliancesRemote,
  closeModal,
  fetchModules,
  isOpen = false,
}: {
  appliancesRemote: ApplianceRemoteDto[];
  closeModal: () => void;
  fetchModules: () => Promise<void>;
  isOpen: boolean | undefined;
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteModuleGuid, setDeleteModuleGuid] = useState("");
  const deleteModuleHandler = async (isForceDelete: boolean) => {
    try {
      await deleteApplianceRemote(deleteModuleGuid, isForceDelete).then(() => {
        fetchModules();
        setShowDeleteConfirmation(true);
        closeModal();
      });
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      maxWidth="lg" // maxWidth is 'lg' on all screens
      fullWidth
    >
      <div>
        <DialogTitle>
          <div className="d-flex justify-content-between">
            <h4>Remote</h4>
            <div className="pointer" onClick={() => closeModal()}>
              <IoMdClose style={{ fontSize: "22px", padding: "0" }} />
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          {appliancesRemote.length > 0 ? (
              <div style={{ maxHeight: "500px", overflowX: "auto", borderRadius: "15px" }}>
                <StyledTableContainer className="">
                  <Table>
                    <StyledTableHead>
                      <TableRow className="">
                        <TableCell scope="col ">#</TableCell>
                        <TableCell scope="col">Remote.Name</TableCell>
                        <TableCell scope="col" style={{ minWidth: "100px" }}>
                          Category
                        </TableCell>
                        <TableCell scope="col">Brand</TableCell>
                        <TableCell scope="col">Model</TableCell>
                        <TableCell scope="col">Command</TableCell>
                        <TableCell scope="col">
                          Remote.Status
                        </TableCell>
                        {/* <TableCell style={{ width: "100px" }}>Remote.Status</TableCell> */}
                        <TableCell>File</TableCell> 
                        {/* <TableCell style={{ width: "200px" }}>File</TableCell> */}
                        <TableCell scope="col">Delete</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {appliancesRemote.map((item, index) => (
                        <TableRow key={item.guid}>
                          <TableCell scope="row">{index + 1}</TableCell>
                          <TableCell>{item.remoteName}</TableCell>
                          <TableCell>{item.applianceCategoryDisplayName}</TableCell>
                          <TableCell>{item.applianceBrandDisplayName}</TableCell>
                          <TableCell>{item.applianceModelDisplayName}</TableCell>
                          <TableCell>{item.command ? item.command : "No Previous Command"}</TableCell>
                          <TableCell className="text-center">{item.remoteStatus}</TableCell>
                          <TableCell className="">
                            <a href={item.irCodeFileDownloadUrl}>
                              <IoMdDownload style={{ fontSize: "22px" }} />
                            </a>
                          </TableCell>

                          <TableCell>
                            <div
                              className="text-danger mx-2 pointer"
                              onClick={() => {
                                setDeleteModuleGuid(item.guid);
                                setShowDeleteConfirmation(true);
                              }}
                            >
                              <RiDeleteBin5Line style={{ fontSize: "18px" }} />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </StyledTableBody>
                  </Table>
                </StyledTableContainer>
              </div>
            ) : (
              <div className="h4 text-muted px-1">
                <em>
                  <BsDatabaseFillX style={{ margin: "0 0 5px 0" }} /> Data Not Found
                </em>
              </div>
            )
          }
          {
            (showDeleteConfirmation &&
              <ShowDeleteConfirmationForRemote
                closeModal={closeModal}
                moduleGuid={deleteModuleGuid}
                deleteModuleHandler={deleteModuleHandler}
                isOpen={showDeleteConfirmation}
              />
            )
          }
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ShowApplianceRemote;