import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InfoModuleData } from "../../helper/Model";
import { updateModuleData } from "../../services/Api";
import { FiEdit } from "react-icons/fi";
import Dialog from "@mui/material/Dialog";
import { IoMdClose } from "react-icons/io";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
const ShowInfoModule = ({
  infoModuleData,
  guid,
  closeModal,
  isOpen = false,
}: {
  infoModuleData: InfoModuleData;
  guid: string | undefined;
  closeModal: () => void;
  isOpen: boolean | undefined;
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const initialValues: InfoModuleData = {
    guid: infoModuleData.guid || "",
    status: infoModuleData.status || "",
    name: infoModuleData.name || "",
    displayName: infoModuleData.displayName || "",
    internalSerialNumber: infoModuleData.internalSerialNumber || "",
    ipAddress: infoModuleData.ipAddress || "",
    moduleTypeDisplayName: infoModuleData.moduleTypeDisplayName || "",
    moduleModelDisplayName: infoModuleData.moduleModelDisplayName || "",
    firmwareVersion: infoModuleData.firmwareVersion || "",
    isMapped: infoModuleData.isMapped || "",
    connectivityStatus: infoModuleData.connectivityStatus || "",
    lastUpdatedByFullName: infoModuleData.lastUpdatedByFullName || "",
    userAddressIdGuid: infoModuleData.userAddressIdGuid || "",
    networkStrength: infoModuleData.networkStrength || 0,
    connectivityStatusTimestamp:
      infoModuleData.connectivityStatusTimestamp || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await updateModuleData(values, infoModuleData.guid)
          .then((response: any) => {
            closeModal();
          })
          .catch((error) => {
            formik.setStatus(error.response.data.data.statusDescription);
          });
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="d-flex justify-content-between">
          <h2>Module Data</h2>
          <div className="pointer">
            <div className="pointer" onClick={() => closeModal()}>
              <IoMdClose style={{ fontSize: "28px", padding: "0" }} />
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="px-4 py-2">
          {formik.status && (
            <div
              className="alert alert-danger p-2 mt-2 text-center"
              role="alert"
            ></div>
          )}
          <form
            className="form w-100 "
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="row">
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="guid">
                  GUID
                </label>
                <input
                  className="form-control radius-10 "
                  type="text"
                  name="guid"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.guid}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="status">
                  Status
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="name">
                  Name
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="displayName">
                  Display Name
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="displayName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.displayName}
                  disabled={isDisabled}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="internalSerialNumber">
                  Internal Serial Number
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="internalSerialNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.internalSerialNumber}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="ipAddress">
                  Ip-Address
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="ipAddress"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ipAddress}
                  disabled={true}
                />
              </div>{" "}
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="moduleTypeDisplayName">
                  Module Type
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="moduleTypeDisplayName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.moduleTypeDisplayName}
                  disabled={true}
                />
              </div>{" "}
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="moduleModelDisplayName">
                  Module Model
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="moduleModelDisplayName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.moduleModelDisplayName}
                  disabled={true}
                />
              </div>{" "}
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="firmwareVersion">
                  Firmware Version
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="firmwareVersion"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firmwareVersion}
                  disabled={true}
                />
              </div>{" "}
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="isMapped">
                  Is-Mapped
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="isMapped"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.isMapped}
                  disabled={isDisabled}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="connectivityStatus">
                  Connectivity-Status
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="connectivityStatus"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.connectivityStatus}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="lastUpdatedByFullName">
                  LastUpdated By
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="lastUpdatedByFullName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastUpdatedByFullName}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="userAddressIdGuid">
                  UserAddress IdGuid
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="userAddressIdGuid"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userAddressIdGuid}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label className="m-0 mt-1" htmlFor="networkStrength">
                  Network Strength
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="networkStrength"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.networkStrength}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <label
                  className="m-0 mt-1"
                  htmlFor="connectivityStatusTimestamp"
                >
                  Connectivity StatusTimestamp
                </label>
                <input
                  className="form-control radius-10"
                  type="text"
                  name="connectivityStatusTimestamp"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.connectivityStatusTimestamp}
                  disabled={true}
                />
              </div>
            </div>
            <br />
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        {!isDisabled ? (
          <>
            <button className="btn btn-primary w-50 mx-4 my-2" type="submit">
              Update
            </button>
            <button
              className="btn btn-danger w-50 mx-4"
              onClick={() => setIsDisabled(true)}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsDisabled(!isDisabled)}
            className="btn btn-primary w-100 mx-4 my-2"
            type="submit"
          >
            Edit
            <FiEdit style={{ color: "white", marginLeft: "10px" }} />
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ShowInfoModule;
