import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

function ShowDeleteConfirmationForRemote({
  moduleGuid,
  closeModal,
  deleteModuleHandler,
  isOpen=false,
}: {
  moduleGuid: string;
  closeModal: () => void;
  deleteModuleHandler: (confirmed: boolean) => Promise<void>;
  isOpen: boolean | undefined;
}) {
  const [confirmText, setConfirmText] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const deleteModuleCaller = async () => {
    try {
      deleteModuleHandler(isChecked);
      closeModal();
    } catch (error) {
      console.error("Error deleting module:", error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.target.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Dialog
    open={true}
    onClose={closeModal}
    maxWidth="sm"
  >
    <DialogTitle>
      <div className="d-flex justify-content-between">
        <h4>Remote</h4>
        <div className="pointer" onClick={closeModal}>
          <IoMdClose style={{ fontSize: "22px", padding: "0" }} />
        </div>
      </div>
      </DialogTitle>

      <DialogContent>
    <div className="">
      <p style={{ fontSize: "15px" }}>
        Your Module GUID is <b>{moduleGuid}</b>
      </p>
      <input
        type="text"
        className="form-control"
        value={confirmText}
        onChange={handleInputChange}
        placeholder="Type 'Delete' to confirm deletion"
      />

      <div className="form-check my-2">
        <input
          type="checkbox"
          className="form-check-input"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className="form-check-label">Hard Deletion</label>
      </div>

      <DialogActions>
      <button
        className="btn btn-danger my-2 mr-2"
        onClick={deleteModuleCaller}
        disabled={confirmText !== "Delete"} // Disable button if confirmText is not "Delete" or checkbox is unchecked
      >
        Delete Module
      </button>
      <button className="btn btn-dark my-2 ml-2" onClick={closeModal}>Cancel</button> {/* On clicking cancel button modal will be closed and ShowApplianceRemote will be toggled back */}
      </DialogActions>
      
    </div>
    </DialogContent>
    </Dialog>
  );
}

export default ShowDeleteConfirmationForRemote;
