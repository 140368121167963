import React from "react";

const DisplayAddressElement = ({
  name,
  value,
}: {
  name: string;
  value: string | undefined;
}) => {
  return (
    <div className="row">
      <div
        className="col-5 mb-1 d-flex align-items-center text-muted"
        style={{ fontSize: "16px" }}
      >
        <div className="d-flex w-100 justify-content-between">
          <div>{name}</div>
          <div>:</div>
        </div>
      </div>
      <div className="col-7 fs-5" style={{ fontSize: "14px" }}>
        <strong>{value}</strong>
      </div>
    </div>
  );
};

export default DisplayAddressElement;
